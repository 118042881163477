<template>
    <div class="">
        <ul v-if="pages" class="flex items-center justify-between">
            <li class="inline-block bg-white  mr-1">
                <nuxt-link
                    v-if="currentPage > 1"
                    :to="checkPage + prevPage"
                    @click.prevent="getPreviousPage"
                    class="no-underline text-blacks-90 block py-1 md:py-2 px-2 md:px-3.5 flex items-center gap-2 b-1 b-[#D0D5DD] font-semibold hover:bg-[#F3F3F3] duration-200"
                >
                    <i class="i-ic:baseline-arrow-back"></i>
                    <p class="hidden md:block">Trang trước</p>
                </nuxt-link>
                <p
                    v-if="currentPage == 1"
                    class="no-underline bg-gray-200 text-white/80 py-1 md:py-2 px-2 md:px-3.5 flex items-center gap-2 b-1 b-[#D0D5DD] font-semibold"
                >
                    <i class="i-ic:baseline-arrow-back"></i>
                    <span class="hidden lg:block">Trang trước</span>
                </p>
            </li>
            <li>
                <ul class="flex items-center gap-0.5">
                    <li
                        v-for="(page, index) in range"
                        :key="index"
                        :class="page != '...' ? 'hover:bg-[#F3F3F3]' : ''"
                        class="inline-block bg-white rounded-lg w-7 h-7 md:w-10 md:h-10 text-sm"
                    >
                        <nuxt-link
                            v-if="page != '...'"
                            :to="checkPage +page"
                            @click.prevent=" page != currentPage ? getPage(page) : false"
                            class="text-[#757575] no-underline rounded-lg w-7 h-7 md:w-10 md:h-10 text-sm font-medium col-flex"
                            :class="{ 'bg-[#F3F3F3] text-blacks-90': page == currentPage }"
                        >
                            <p>{{ page }}</p>
                        </nuxt-link>
                        <span
                            v-else
                            class=" text-[#757575] no-underline rounded-lg w-7 h-7 md:w-10 md:h-10 text-sm font-medium col-flex duration-200"
                        >
                            {{ page }}
                        </span>
                    </li>
                </ul>
            </li>
            <li class="inline-block bg-white  ml-1">
                <nuxt-link
                    v-if="currentPage < pages "
                    :to="checkPage + nextPage"
                    @click.prevent="getNextPage"
                    class="no-underline text-blacks-90 block py-1 md:py-2 px-2 md:px-3.5 flex items-center gap-2 b-1 b-[#D0D5DD] font-semibold hover:bg-[#F3F3F3] duration-200"
                   
                >
                    <p class="hidden lg:block">Trang sau</p>
                    <i class="i-ic:baseline-arrow-forward"></i>
                </nuxt-link>
                <p
                    v-else
                    class="no-underline bg-gray-200 text-white/80 py-1 md:py-2 px-2 md:px-3.5 flex items-center gap-2 b-1 b-[#D0D5DD] font-semibold"
                >
                <p class="hidden md:block">Trang sau</p>
                <i class="i-ic:baseline-arrow-forward"></i>
            </p>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    pages: {
        type: Number,
        default: 0
    },
    currentPage: {
        type: Number,
        default: 1
    }
})
const route = useRoute()
const urlNotPage = route.fullPath.replace(/[\?&]page=.*/, '');
const nextPage =computed( ()=>props.currentPage + 1)
const prevPage = computed(()=>props.currentPage - 1)
const range = ref([])
const propsToWatch = computed(() => {
    return [props.pages, props.currentPage, Date.now()]
})
const checkPage = computed(()=>{
   if((route.query.hasOwnProperty('page') && Object.keys(route.query).length == 1) || Object.keys(route.query).length == 0){
    return urlNotPage  + '?page='
   }
   return urlNotPage +  '&page='
  
})
const organisePageLinks = () => {
    range.value = []
    for (let i = 1; i <= props.pages; i++) {
        if (
            i == 1 || // first page
            i == props.pages || // last page
            i == props.currentPage || // current page
            i == props.currentPage - 1 || // page before current
            i == props.currentPage + 1 || // page after current
            (i <= 3 && props.currentPage < 3) || // "filler" links at start
            (i >= props.pages - 3 && props.currentPage > props.pages - 3) // "filler" links at end
        ) {
            let index = range.value.length
            if (index > 0 && range.value[index - 1] < i - 1) {
                // if this page is not the next one insequence, add in 3 dots "..."
                range.value.push('...')
            }
            range.value.push(i)
        }
    }
}

watchEffect(() => {
    organisePageLinks(propsToWatch.value)
})
const emit = defineEmits(['page-changed'])
const getPage = (page: number) => {
    emit('page-changed', page)
}
const getPreviousPage = () => {
    getPage(props.currentPage - 1)
}
const getNextPage = () => {
    getPage(props.currentPage + 1)
}
</script>
